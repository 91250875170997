@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
body{
    background-color: #eee;
    font-family: 'Roboto', sans-serif;
    color: #666;
}

.login-wrapper{
    width: 50vh;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 7px;
    margin-top: 20vh;
}

.login-wrapper h1{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.cms-wrapper{
    padding-top: 70px;
}

.content-item{
    background-color: #fff;
    border: 1px solid #ddd;
}

ul.gallery-images li,
ul.gallery-images li img
{
    max-height: 150px;
    max-width: 150px;
}